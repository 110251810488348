import React from "react"

import Layout from "../components/Layout/Layout"

const Page404 = () => {
  return (
    <Layout>
      <div style={{ height: "60px" }} />
      <h1>404 strona nie istnieje</h1>
    </Layout>
  )
}

export default Page404
